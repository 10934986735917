@import '../../../styles.scss';
.leftSidebar {
    background: #fff;
    width: 18%;
    top: 0;
    left: 0;
    position: fixed;
    bottom: 0;
    padding-top: 85px;
    box-shadow: 0 0.125rem 0.375rem 0 rgba(161,172,184,.12);
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #CCCCCC transparent;
    scrollbar-width: thin;
    // .p-menubar.p-component {
    //     max-height: 585px;
    //     overflow-y: auto;
    //     -webkit-overflow-scrolling: touch;
    //     scrollbar-color: #CCCCCC transparent;
    //     scrollbar-width: thin;
    //     padding-bottom: 25px;
    //     padding-top: 90px;
    //     padding-left: 0;
    //     padding-right: 0;
    //     &::-webkit-scrollbar {
    //         width: 4px;
    //         height: 4px;
    //         background-color: transparent;
    //         border-radius: 2px;
    //     }
    //     &::-webkit-scrollbar-thumb {
    //         background-color: #CCCCCC;
    //         border-radius: 2px;
    //     }
    //     &::-webkit-scrollbar-track {
    //         border: 0.0625rem solid transparent;
    //         border-radius: 2px;
    //     }
    // }
    // .p-menubar {
    //     padding: 0.5rem;
    //     background: transparent;
    //     border: 0;
    //     border-radius: 0px;
    //     .p-menubar-root-list {
    //         flex-direction: column;
    //         align-items: center;
    //         justify-content: center;
    //         width: 100%;
    //     }
    // }
    // .p-menuitem {
    //     position: relative;
    //     width: 100%;
    //     span.p-menuitem-text {
    //         font-family: 'PublicSans-Medium';
    //         color: #697a8d !important;
    //         font-size: 14px;
    //         text-transform: capitalize;
    //         line-height: 24px;
    //     }
    //      .p-submenu-icon {
    //         color: #12262f !important;
    //         margin-left: auto !important;
    //     }
    // }
    // .p-menubar .p-submenu-list {
    //     position: relative;
    //     z-index: 1;
    //     width: 100%;
    //     box-shadow: none;
    //     background: transparent;
    //     padding-left: 12px;
    // }
    // .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link{
    //     border-radius: 0 !important;
    //     border-left: 3px solid transparent;
    //     outline: none !important;
    //     box-shadow: none !important;
    // }
    // .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    //     background: #fff;
    //     border-left: 3px solid #efb34c;
    //     .p-menuitem-text{
    //         color: #efb34c !important;
    //     }
    //     .p-menuitem-icon{
    //         color: #efb34c !important;
    //     }
    // }
    .leftSidebar__menu{
        ul{
            padding-left: 0px;
            li{
                list-style: none;
                a,.sidebar-a{
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    font-family: 'PublicSans-Medium';
                    color: #697a8d !important;
                    font-size: 14px;
                    text-transform: capitalize;
                    line-height: 24px;
                    border-left: 3px solid transparent;
                    .pi{
                        font-size: 20px;
                        margin-right: 10px;
                        &.ml-auto{
                            margin-left: auto;
                            margin-right: 0;
                        }
                    }
                    &:hover{
                        background: #fdf6f2;
                        border-left: 3px solid #f0844a;
                        cursor: pointer;
                        .p-menuitem-text{
                            color: #efb34c !important;
                        }
                        .p-menuitem-icon{
                            color: #efb34c !important;
                        }
                    }
                }
                .child-menu{
                    a,.sidebar-a{
                        padding: 10px 20px 10px 52px;
                    }
                }
                .activeLink{
                    background: #fdf6f2;
                    border-left: 3px solid #f0844a;
                    cursor: pointer;
                    .p-menuitem-text{
                        color: #efb34c !important;
                    }
                    .p-menuitem-icon{
                        color: #efb34c !important;
                    }
                }
            }
        }
    }
    &::-webkit-scrollbar {
                 width: 4px;
                 height: 4px;
                 background-color: transparent;
                 border-radius: 2px;
             }
             &::-webkit-scrollbar-thumb {
                 background-color: #CCCCCC;
                 border-radius: 2px;
             }
             &::-webkit-scrollbar-track {
                 border: 0.0625rem solid transparent;
                 border-radius: 2px;
            }
}

@import url('assets/admin-cutome.scss');
body{
    margin: 0;
    font-family: 'OpenSans-Regular';
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}
.sweet-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 16%);
    z-index: 9999;
}
.nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
    height: 48px;
    border: 1px solid #dadce0 !important;
    background: red !important;
}
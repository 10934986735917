@import '../../../styles.scss';
.header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    left: 0;
}
.header{
    background-color: $text;
    min-height: 80px;
    display: flex;
    align-items: center;
    .header-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .down-arrow{
            filter: contrast(0) brightness(5);
        }
        .header-left{            
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .header-logo{
                width: 110px;
                height: auto;
                margin: 0 0px;
            }
            .p-button{
                margin: 0px 30px;
                display: flex;
                align-items: center;
                .down-arrow{
                    margin-left: 10px;
                    margin-top: 5px;
                    position: relative;
                }
            }
        }
        .header-center{
            display: flex;
            justify-content: center;
            .p-inputtext{
                width: 388px;
            }
        }
        .header-right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .p-overlay-badge{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .header__user{
                display: flex;
                align-items: center;
                margin: 0 15px;
                cursor: pointer;
                span{
                    display: flex;
                    align-items: center;
                    .pi-angle-down{
                        color: $white;
                    }
                }
                img.down-arrow {
                    margin-left: 8px;
                }
            }
        }
    }
}
.top-toolbar-right-item{
    position: relative;
    .language-drop{
        display: flex;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
        .language-text{
            font-family: "PublicSans-Regular";
            font-size: 12px;
            color: #fff;
            margin: 0px 4px;
        }
    }
    .currency-drop{
        display: flex;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
        .currency-text{
            font-family: "PublicSans-Regular";
            font-size: 12px;
            color: #fff;
            margin: 0px 4px;
        }
    }  
    &~.top-toolbar-right-item{
        border-left: 1px solid $text;
        margin-left: 8px;
    } 
}
.headerselectdb{
    .p-dropdown {
        background: transparent !important;
        border: 1px solid transparent !important;
        outline: none !important;
        box-shadow: none !important;
        .p-inputtext{
            color: #fff !important;
            font-size: 14px;
        }
        .p-dropdown-trigger {
            width: 1rem;
            .p-clickable {
                color: #fff !important;
                font-size: 12px !important;
            }
        }
    }
}
.progileimg {
    background: #ccc;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}
.headerlogo{
    width: 150px;
}
.selectCountrySwich{
    color: #fff;
    font-family: 'PublicSans-Regular';
    font-size: 14px;
    .CName {
        display: block;
        margin: 0 10px;
    }
    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
    .p-inputswitch .p-inputswitch-slider {
        background: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%);
    }
    .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
        background: linear-gradient(113.49deg, #E8336E 10.85%, #F9B000 77.92%);
    }
}
@import "../../../styles.scss";
.Login-page {
    background: #12262F;
    min-height: 100vh;
    max-height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    .LoginformContent{
        width: 100%;
        padding: 20px;
        .LoginForm {
            background: #fff;
            padding: 30px 20px;
            border-radius: 6px;
            a{
                text-decoration: none;
            }
            .btn-blue{
                text-align: center;
                display: block;
                width: 100%;
            }
        }
    }
}
.forgotpassword {
    font-size: 14px;
    margin: 0 0px 10px auto;
    text-align: right;
    padding: 0 !important;
    cursor: pointer;
    color: #12262F;
    font-family: 'PublicSans-Regular';
}
.dialogWidth400 {
    width: 400px !important;
    .btn{
        width: 100%;
        display: block;
        text-align: center;
    }
}
.headetPadding{
    .p-dialog-header{
        padding-bottom: 0;
    }
}
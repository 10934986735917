// BUTTONS
.p-button{
    font-size: 16px;
    font-family: 'PublicSans-SemiBold'; 
    background: $yellow;
    color: $white;
    border: $yellow;
    outline: none !important;
    box-shadow: none !important;
    &:hover,&:active,&:focus,&:focus-visible,&:enabled:hover{
        background: $yellow;
        color: $white;
        border: $yellow;
    }
}
// .p-badge
.p-badge{
    height: 1rem;
    min-width: 1rem;
    line-height: 1rem;
    background: $error;
    font-size: 10px;
}

.prime-height58{
    height: 58px;
    width: 100%;
    &.p-inputwrapper-focus{
        border-color:$yellow;
        &:focus,&:enabled:hover,&.p-focus{
            border-color:$yellow !important;
        }
    }
}
.p-inputtext{
    border: 1px solid $gray;
    outline: none;
    box-shadow: none !important;
    &:focus,&:enabled:hover,&.p-focus{
        border-color:$yellow !important;
    }    
}
.p-float-label {
    // &>label {
    //     left: auto;
    //     right: 0.75rem;
    //     color: $gray !important;
    // }
    .p-inputwrapper-filled~label,
    .p-inputwrapper-focus~label {
        // left: auto;
        // right: 0.75rem;
        top: 0.25rem;
        font-size: 12px;
        width: auto;
        background: white;
        padding: 0 4px;
    }
    .p-dropdown {
        width: 100%;
        min-height: 56px;
        border-color: var(--gray) !important;
        border-radius: 8px !important;
        &:focus,
        &.p-focus {
            border-width: 2px;
            border-color: var(--yel$yellow) !important;
            box-shadow: none !important;
            outline: none !important;
        }
        .p-dropdown-trigger {
            width: 2rem;
            .p-dropdown-trigger-icon {
                border: 3px solid transparent;
                border-bottom: 3px solid;
                border-left: 3px solid;
                transform: rotate(-45deg) scale(1.18);
            }
        }
    }
}

.p-datepicker{
    min-width: 352px !important;
    width: 352px !important;
    .p-datepicker-header {
        .p-datepicker-title,.p-link{
            color: $text !important;
        }
    }
    .p-highlight{
        background-color: $yellow !important;
        color: white !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }
    table {
        td{
            padding: 0.25em !important;
            .p-highlight{
                background-color: $yellow !important;
                color: white !important;
            }
        }
    }
}
.p-outline-error{
    .p-inputnumber-input{
        border-color: $error !important;
        &:focus,&:enabled:hover,&.p-focus{
            border-color:$error !important;
        }
    }
}
.modal-close{
    background: transparent;
    border: none;
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 24px;
}

.p-inputnumber{
    width: 100%;
    position: relative;
}
.input-parent{
    position: relative;
}
.otp-input-text{
    position: absolute;
    top: 24%;
    right: 10px;
    cursor: pointer;
    color: $yellow;
}
// text heading paragraph

.h1{
    @include h1-heading($text);
}
.h2{
    @include h2-heading($text);
}
.h3{
    @include h3-heading($text);
}
.h4{
    @include h4-heading($text);
}
.h5{
    @include h5-heading($text);
}
.h6{
    @include h6-heading($text);
}
.p1{
    @include p1-paragraph($text);
}
.p2{
    @include p2-paragraph($text);
}
.p3{
    @include p3-paragraph($text);
}
.p4{
    @include p4-paragraph($text);
}
// link
.link{
    @include p2-paragraph($yellow, $family: 'PublicSans-SemiBold');
    text-decoration: none;
    display: flex;
    align-items: center;
    .link-content{
        margin: 0px 8px;
    }
    .pi{
        color: $yellow;
        transition: all 0.3s ease-in-out;
    }  
    &:hover{
        .pi{
            transform: translateX(5px);
        }
    }
}

body[style~="padding-left:17px"]{
    
}
.container{
    width: 100%;
    max-width: 1266px;
    margin: auto;
}
.icon24{
    width: 24px;
    height: 24px;
    object-fit: contain;
}
// toolbar sticky
.toolbar-sticky{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $outline;
    height: 44px;
    .toolbar-content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .toolbar-link{
            display: flex;
            align-items: center;
            text-decoration: none;
            margin: 0px 18px;
            &:first-child{
                margin-left: 0px;
            }
            &:last-child{
                margin-right: 0px;
            }
            .icon24{
                filter: contrast(0) brightness(0.5);
            }
            .tool-content{
                @include p2-paragraph($text);
                margin: 0px 8px;
            }
            &:focus,&:active,&.active{
                .icon24{
                    filter: contrast(1) brightness(1);
                }
                .tool-content{
                    color: $yellow;
                }
            }   
        }
    }
}

// chips
.chips{
    padding: 4px 14px;
    border-radius: 6px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px !important;
    font-family: 'PublicSans-Medium' !important;
    font-weight:500;
    &.error{
        background-color: $error;
    }
    &.success{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.danger{
        background: #FCF3F2;
        color: #D23D50;
    }
    &.confirmed{
        background: #dcf3fd;
        color: #0c89bf;
    }
    &.shipped{
        background: #FFF6ED;
        color: #EE9C16;
    }
    &.delivered{
        background: #EFF8EB;
        color: #59AA00;
    }
    &.canceled{
        background: #f7ccc8;
        color: #cb1f35;
    }
    &.cancelled{
        background: #f7ccc8;
        color: #cb1f35;
    }
    &.returned{
        background: #fddeda;
        color: #ad2a3a;
    }
    &.pickup{
        background: #c9f7e6;
        color: #36267c;
    }
    &.pending{
        background: #c9d7f7;
        color: #5e548e;
    }
}

// product box
.product-box{
    transition: all 300ms;
    .product-image-box{
        width: 100%;
        height: 243px;
        background-color: $white;
        border-radius: 8px;
        overflow: hidden;
        display: flex;  
        align-items: center;
        justify-content: center;
        position: relative;
        .product-image{
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
        .add-to-card-btn{
            height: 54px;
            width: calc(100% - 40px);
            position: absolute;
            bottom: -55px;
            visibility: hidden;
            opacity: 0;
            background-color: $cta2;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 300ms;
            @media (max-width: 899px) {
                bottom: 12px;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .product-content{
        padding: 20px 0px;
        .p3{
            @include p3-paragraph($cta2, $family: 'PublicSans-SemiBold');
            margin-bottom: 8px;
        }
        .h6{
            @include h6-heading($text, $family: 'PublicSans-Bold');   
            margin-bottom: 8px;
            font-size: 14px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .price-row{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .p2{
                @include p2-paragraph($text, $family: 'PublicSans-Bold');
                margin-bottom: 0px;
            }
            .p3{
                @include p3-paragraph($error, $family: 'PublicSans-Regular');
                text-decoration: line-through;
                margin: 0px 8px;
            }
        }
    }
    &:hover{
        .product-image-box{
            .add-to-card-btn{
                visibility: visible;
                opacity: 1;
                bottom: 12px;
            }
        }
    }
}


.tablefilterSubheader{
    border-bottom: 1px solid #d9dee3 !important;
    padding: 24px 24px;

}

.tablefilterheader{
    .tableFilterCol{
        margin-left: 20px;
        width: 250px;
        .p-dropdown.p-component.p-inputwrapper {
            width: 100% !important;
        }
    }
}
.fieldLeftCaption .currencycapstiondd {
    
    background: #f2f2f2;
}

.sidebarContentBody{
    .p-invalid {
        bottom: -11px !important;
    }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%) !important;
}
.p-inputswitch .p-inputswitch-slider {
    outline: 0 !important;
    box-shadow: none !important;
   
}

.show-dataonly-static{
    width: 100%;
    min-height: 38px;
    border: 1px solid #80808096;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}
.currencyConversionpage{
    .p-error {
        position: absolute;
        bottom: -16px;
        font-size: 12px;
        font-family: 'PublicSans-Regular' !important;
    }
}

.p-editor-container{
    button.ql-link {
        display: none !important;
    }
    button.ql-image {
        display: none !important;
    }
    button.ql-code-block {
        display: none !important;
    }
    button.ql-clean {
        display: none !important;
    }
}
.sharlisttable {
    th{
        padding: 10px 15px !important;
        vertical-align: middle;
    }
    td{
        padding: 10px 15px !important;
        vertical-align: middle;
    }
    span.MuiButtonBase-root.MuiCheckbox-root {
        margin: 0 !important;
        padding: 0 15px 0 0;
    }
}
.setpassworpage{
    .p-float-label label {
        top: 40%;
    }
}
.p-card.new-card{
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100%;
    flex-direction: row;
    .p-card-body{
        width: 100%;
    }
}

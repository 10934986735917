@import "../styles.scss";

.admin-content-wrapper {
    background: $grayf5;
    min-height: 100vh;
}

.admin-content-wrapper {
    width: 82%;
    margin-left: auto;
    padding: 78px 20px 20px 20px;

    .adminContent {
        margin-top: 25px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 2px 6px 0 rgba(67, 89, 113, .12);

        .h4 {
            color: $black566 !important;
        }

        table.p-datatable-table {
            min-width: 100% !important;
        }

        .tablefilterheader {
            padding: 24px 24px 24px 24px;
        }

        .tableHeader {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .tableFilterRow {
                display: flex;
                align-items: center;
                justify-content: end;
                margin-left: auto;
                flex-wrap: wrap;
                row-gap: 10px;

                .tableFilterCol {
                    margin-left: 20px;

                    .p-dropdown {
                        background: transparent !important;
                    }

                    .p-dropdown .p-dropdown-label {
                        padding: 8px 10px !important;
                        height: 38px;
                        min-width: 50px !important;

                        // text-transform: uppercase;
                        // font-size: 14px;
                    }

                    .searchFilter {
                        position: relative;

                        .p-inputtext.p-component {
                            height: 38px;
                            border: 1px solid $grayd2;
                            background: transparent;
                        }
                    }

                    .p-calendar {
                        .p-inputtext {
                            padding-top: 6px !important;
                            padding-bottom: 7px !important;
                            background: transparent;
                            border: 1px solid $grayd2;
                            height: 38px;
                        }
                    }

                    &.dateFilter {
                        width: 155px;
                        max-width: 155px;
                        min-width: 155px;
                    }

                    .btn.btn-yellow {
                        height: 42px;
                        padding: 0 13px !important;
                        font-size: 14px;
                    }

                    .exportbtn {
                        position: relative;

                        img.down-arrow {
                            position: absolute;
                            right: 8px;
                            top: 11px;
                        }
                    }
                }
            }
        }
    }

    .p-datatable-wrapper {
        overflow: auto;
    }
}

.tableContent {
    overflow-y: auto;
    max-height: 70vh;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: $grayd2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $grayd2;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }

    table {
        caption-side: bottom;
        border-collapse: collapse;
        display: table;
        box-sizing: border-box;
        width: 100%;

        tr {
            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
    }

    thead {
        th {
            font-family: 'PublicSans-Bold';
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 600 !important;
            display: table-cell;
            text-align: left;
            background: $white;
            padding: 10px 10px;
            vertical-align: top;
            position: sticky;
            top: 0;
            border-spacing: 0;
            white-space: nowrap;
            z-index: 1;
            text-transform: capitalize;
            border-bottom: 1px solid $grayd9;
            color: $black566 !important;
            text-transform: uppercase;
            background: #fbfbfb;

            &:first-child {
                padding-left: 26px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }

    tbody {
        td {
            font-family: 'PublicSans-Medium';
            margin-bottom: 8px;
            font-size: 15px;
            display: table-cell;
            text-align: left;
            border-bottom: 1px solid $grayd9;
            padding: 10px 10px;
            border-spacing: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 235px;
            overflow: hidden;

            color: $black566 !important;

            .tba {
                font-family: 'PublicSans-SemiBold';
                cursor: pointer;
                font-size: 15px;

                &:hover {
                    color: $yellow !important;
                }
            }

            .tbaBlue {
                font-family: 'PublicSans-SemiBold';
                cursor: pointer;
                font-size: 15px;
                color: $yellow !important;

                &:hover {
                    color: $yellow !important;
                }
            }

            .tbp {
                color: #a1acb8 !important;
                margin-top: 5px;
                font-size: 13px;
            }

            .addSpecificatioTd {
                font-size: 13px !important;
                padding: 9px 10px;
            }

            &:first-child {
                padding-left: 26px;
            }

            &:last-child {
                padding-right: 26px;
            }

            &.tDaddredd {
                width: 200px;
                font-family: 'PublicSans-Regular' !important;
            }

            &.tdAction {
                .delteIcon {
                    color: $text;
                }

                .editIcon {
                    color: $text;
                }

                a {
                    margin: 0 10px;
                }
            }

            &.description {
                width: 415px;
            }

            .productImageTd {
                width: 40px;
                height: 40px;
                border: 1px solid $grayd2;
                object-fit: contain;
                padding: 2px;
                margin-right: 5px;
                cursor: pointer;
                border-radius: 4px;
            }

            .actionbtn {
                background: transparent;
                color: #000;
                width: auto !important;

                .TdICon {
                    margin-left: 15px !important;
                }

                .p-button.p-button-icon-only {
                    padding: 0 !important;
                }

                &:hover {
                    background: transparent;
                    color: #000;
                }
            }

            .featImageTa {
                display: flex;

                .productImageTd {
                    border: 1px solid $grayd2;
                    object-fit: contain;
                    padding: 2px;
                    margin-right: 5px;
                    cursor: pointer;
                }
            }

            .addmorCircleBtn {
                margin: 0 auto;
            }

            .product-title {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 220px;
                overflow: hidden;
                margin: 0px 10px;
            }

            tr {
                th {
                    position: relative;
                    z-index: 0;
                }
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: 1px solid $grayd9;
                }
            }
        }
    }

    .tbleheading {
        position: relative;
        display: inline-block;
        margin: 0 !important;
        padding-right: 10px;

        img.sortIconUp {
            width: 15px;
        }

        .sortIconDown {
            width: 15px;
            margin-top: -3px;
        }

        .sort-arrow-table {
            position: absolute;
            top: -4px;
            height: auto;
            right: -9px;
            cursor: pointer;
            bottom: 0;
            margin: auto;
            line-height: normal;
            padding-top: 0px;
            opacity: 0.8;

            .sortIconUp {
                display: block;
                margin: 0;
                color: #666;
                transform: rotateX(-180deg);
            }

            .sortIconDown {
                transform: rotateX(0deg);
                position: relative;
                top: -6px;
            }
        }
    }
}

.contentBox-w {
    background: $white;
    border-radius: 8px;
    padding: 20px;

    p.p1 {
        font-weight: 600;
        font-family: 'PublicSans-SemiBold';
        font-size: 14px;
    }
}

.formField {
    padding-bottom: 8px;

    .p-inputtext {
        width: 100%;
        padding-left: 16px;
        font-family: 'PublicSans-Regular' !important;
    }

    .p-float-label>label {
        left: 16px !important;
        color: $gray !important;
        background: $white;
        display: inline-block !important;
        right: auto !important;
        padding: 0 3px;
        font-family: 'PublicSans-Regular' !important;
    }

    .p-float-label input:focus~label,
    .p-float-label input:-webkit-autofill~label,
    .p-float-label input.p-filled~label,
    .p-float-label textarea:focus~label,
    .p-float-label textarea.p-filled~label,
    .p-float-label .p-inputwrapper-focus~label,
    .p-float-label .p-inputwrapper-filled~label {
        top: 3px;
        font-size: 12px;
    }

    .p-float-label input:focus~label {
        color: $yellow !important;
    }

    .p-float-label .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
        border: 0 !important;
        transform: rotate(0deg) scale(1);
    }

    .p-float-label .p-dropdown {
        min-height: 47px;
    }

    .p-calendar-w-btn {
        width: 100%;
    }

    .p-inputnumber {
        width: 100%;
    }

    .p-button {
        background: #f5f5f5;
        border: 1px solid $gray !important;
        color: #6A6A71;
        border-left: 0 !important;

        &:hover {
            background: $grayd2;
            border: 1px solid $grayd2 !important;
            color: #6A6A71;
        }
    }

    .p-password {
        width: 100%;

        label {
            font-family: 'PublicSans-Medium' !important;
        }
    }

    .p-dropdown {
        height: 45px;

        span.p-dropdown-label.p-inputtext {
            color: $gray !important;
            font-size: 15px;
            font-family: 'PublicSans-Regular' !important;
        }
    }

    .pfloatlabel {
        .p-dropdown-label.p-inputtext {
            padding: 13px 16px !important;
            height: 45px;
        }
    }

    textarea.p-inputtextarea.p-inputtext.p-component {
        min-height: 115px;
    }
}

.uploadImageRow {
    display: flex;

    .uploadImageCol {
        background-color: $outline;
        min-width: 130px;
        max-width: 130px;
        height: 130px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        margin-right: 15px;

        input[type="file"] {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            font-size: 0;
            opacity: 0;
        }

        &.uploadImagePreview {
            .productImage {
                width: 100%;
                height: 100%;
                border-radius: 6px;
                object-fit: cover;
            }

            .deleteICon {
                position: absolute;
                top: 5px;
                right: 5px;
                background: $error;
                color: $white;
                font-size: 12px;
                padding: 5px 5px;
                border-radius: 50px;
                cursor: pointer;
                z-index: 1;
            }

            .featureIcon {
                position: absolute;
                top: 5px;
                left: 5px;
                color: $yellow;
                cursor: pointer;
                z-index: 1;
            }
        }

        .p2 {
            font-size: 10px;
        }

        &.uploadImageBox {
            padding: 6px;
        }
    }
}

.p-sidebar-right .p-sidebar {
    width: 25rem;
    height: 100%;
}

.detailSiderbar {
    .sidebarContentBody {
        .uploadImageRow {
            flex-wrap: wrap;

            .uploadImageCol {
                min-width: 60px;
                max-width: 60px;
                height: 60px;
                padding: 5px;
                margin-bottom: 10px;
                margin-right: 10px;

                .productImage {
                    object-fit: contain;
                }
            }
        }

        .formField {
            padding-bottom: 4px;
        }
    }

    .p-sidebar-header {
        position: absolute;
        top: 0;
        right: 15px;
        z-index: 1111;
        background: $white;
        padding: 8px !important;
    }

    .sidebarTitleHd {
        margin: 0px;
        border-bottom: 1px solid $grayd2;
        padding: 20px;
        font-family: 'PublicSans-Bold';
        position: fixed;
        top: 0;
        width: 100%;
        background: $white;
        z-index: 1;

        span {
            font-family: 'PublicSans-SemiBold';
        }
    }
}

.btn.btn-green {
    background: #64CD82;

    &:hover {
        background: #64CD82 !important;
    }
}

.btn.btn-gray {
    background: $grayf5;
    color: $gray;
    border: 1px solid #e7e7e7 !important;

    &:hover {
        background: $grayf5 !important;
        color: $gray !important;
    }

    &:focus {
        background: $grayf5 !important;
        color: $gray !important;
    }
}

.sidebarFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-top: 1px solid $grayd2;
    padding: 15px;
    display: flex;
    justify-content: end;
    background: $white;

    .btn {
        margin-left: 15px !important;
    }
}

.sidebarContentBody {
    padding: 80px 20px 0 20px;

    .specList {
        .p1 {
            padding-left: 30px;
            position: relative;

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background: $black566;
                display: block;
                position: absolute;
                left: 12px;
                top: 9px;
                border-radius: 50px;
            }
        }
    }
}

.fieldLeftCaption {
    position: relative;
    display: flex;

    .currencycapstiondd {
        max-width: 30%;
        min-width: 30%;
        border: 1px solid $gray;
        border-radius: 6px 0 0px 6px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        .p-dropdown {
            width: 100%;
        }

        .p-dropdown .p-dropdown-trigger {
            width: 2rem;
        }

        span.p-dropdown-label.p-inputtext {
            padding-right: 0;
            font-size: 14px;
            padding-top: 14px;
        }
    }

    span.p-float-label {
        min-width: 70% !important;
        max-width: 70% !important;

        .p-inputtext {
            border: 1px solid $gray;
            border-radius: 0 6px 6px 0px;
            border-left: 0;
        }
    }

    .p-dropdown {
        border: 0;
        border-radius: 0;
        background: transparent !important;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.readonlyfield {
    .p-inputtext {
        background: $grayf5;
        pointer-events: none;
    }

    .p-dropdown {
        background: $grayf5;
        pointer-events: none;
    }
}

.addformDialog {
    .addmorBtn {
        margin-top: 8px;
    }

    .removeBtn {
        margin-top: 8px;
    }

    h3 {
        font-family: 'PublicSans-Medium';
        font-weight: 500;
        font-size: 26px;
    }
}

.p-dialog.p-component.addWholsaleDialog {
    width: 90vw !important;

    .divider {
        width: 100%;
        background: $grayd2;
        height: 1px;
        margin: 0px auto 10px auto;
    }

    .wsaleCol {
        padding: 8px;
        padding-bottom: 0;

        .formField .p-float-label>label {
            font-size: 15px;
        }
    }

    .MinQuantityCol {
        min-width: 17%;
        max-width: 17%;
    }

    .MaxQuantityCol {
        min-width: 16%;
        max-width: 16%;
    }

    .WholesalePriceCol {
        min-width: 12%;
        max-width: 12%;
    }

    .DiscounttypeCol {
        max-width: 15%;
        min-width: 15%;
    }

    .DiscountvalueCol {
        max-width: 13%;
        min-width: 13%;
    }

    .SellingPriceCol {
        max-width: 22%;
        min-width: 22%;

        .p-dropdown .p-dropdown-trigger {
            width: 18px !important;
            margin-right: 6px;
        }

        .fieldLeftCaption .currencycapstiondd {
            max-width: 42%;
            min-width: 42%;
        }

        .fieldLeftCaption span.p-float-label {
            min-width: 58% !important;
            max-width: 58% !important;
        }
    }

    .wsaleCol.WSaleBntCol {
        min-width: 5%;
        display: flex;
        justify-content: center;
    }
}

.addmorCircleBtn {
    background: transparent !important;
    width: 36px;
    height: 36px;
    padding: 0 14px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
}

.removeCircleBtn {
    background: transparent !important;
    width: 36px;
    height: 36px;
    padding: 0 14px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
}

.addmorCircleBtn {
    color: $yellow !important;
    border: 1px solid $yellow !important;
}

.removeCircleBtn {
    color: $error !important;
    border: 1px solid $error !important;
}

.dialogFooter {
    border-top: 1px solid $grayd2;
    padding-top: 30px;
    margin-top: 20px;

    .btnsRow {
        display: flex;
        justify-content: end;

        .btn {
            margin-left: 15px !important;
        }
    }
}

.uploadImageTd {
    position: relative;
    display: flex;
    background: $yellow;
    width: 36px;
    height: 36px;
    color: $white;
    border-radius: 50px;
    align-items: center;
    justify-content: center;

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 0;
        opacity: 0;
    }
}

.catimagePreview {
    position: relative;
    display: flex;

    width: 36px;
    height: 36px;
    color: $white;
    border-radius: 4px;
    align-items: center;
    justify-content: center;

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 0;
        opacity: 0;
        left: 0;
        top: 0;
    }

    .uploadnewImage {
        position: absolute;
        top: -6px;
        background: $white;
        color: $white;
        width: 24px;
        height: 24px;
        text-align: center;
        padding: 1px;
        border-radius: 50px;
        right: -4px;
        border: 1px solid $yellow;
        display: none;

        .uploadIcon {
            width: 15px;
        }
    }

    &:hover {
        .uploadnewImage {
            display: block;
        }
    }
}

.wsaleRow {
    padding-top: 20px;
    max-height: 385px;
    overflow: auto;
}

.addquantityCol {
    max-width: 18%;
    min-width: 18%;
}

.orderstatusChart {
    width: 100% !important;
    max-width: 100% !important;

    canvas {
        width: 326px !important;
        height: auto !important;
    }
}

.paymentOption {
    background: $outline;
    padding: 20px;
    border-radius: 8px;

    label {
        font-size: 14px;
        font-family: 'PublicSans-SemiBold';
    }

    .p-radiobutton .p-radiobutton-box {
        border: 2px solid $yellow;
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: $yellow;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: $yellow;
        background: $white;
    }

    .p-radiobutton .p-radiobutton-box {
        margin-top: -10px;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: $yellow;
        background: $white;
    }

    .p3 {
        color: $gray;
        font-family: 'PublicSans-Regular';
    }
}

.p-checkbox.p-component+label {
    font-size: 14px;
}

.p-sidebar-content {
    padding: 0 !important;
}

.tabContainer {
    li.p-unselectable-text {
        margin-right: 28px !important;

        a.p-tabview-nav-link {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .p-tabview-panels {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.frmLabel {
    display: block;
    margin-bottom: 10px;
    font-family: 'PublicSans-SemiBold';
}

.p-tooltip-arrow {
    border-top-color: $white !important;

}

.p-tooltip-text {
    background-color: $white !important;
    color: $text !important;
    font-size: 12px;
    max-width: 250px;
}

.p-dialog-content {
    padding-top: 10px !important;
}

.passwordIcon {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
    color: rgb(249, 176, 0);
}

.markproductTd {
    max-width: 100% !important;

    .markproductRw {
        display: flex;

        .markproductCl {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 33%;
            max-width: 33%;
            padding: 0 10px;

            label {
                display: block;
                margin-bottom: 8px;
            }
        }
    }
}

.prRow {
    display: flex;

    .prCol {
        padding-right: 15px;

        h6 {
            font-family: 'PublicSans-Bold';
            font-size: 14px;
            margin-bottom: 0
        }

        p {
            font-family: 'PublicSans-Regular';
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
        }

        &:nth-child(1) {
            min-width: 35%;
            max-width: 35%;
        }

        &:nth-child(2) {
            min-width: 20%;
            max-width: 20%;
        }

        &:nth-child(3) {
            min-width: 15%;
            max-width: 15%;
        }

        &:nth-child(4) {
            min-width: 30%;
            max-width: 30%;
            padding-right: 0;
            padding-left: 15px;
        }
    }
}

.markprheaderrw {
    display: flex;
    display: flex;
    min-width: 300px;

    .markprheaderCl {
        min-width: 33%;
        max-width: 33%;
        padding: 0 10px;
        margin-top: 5px;
        font-family: 'PublicSans-SemiBold';
        font-weight: 600;
    }
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-bottom: 80px !important;
}

.dilogbtns {
    display: flex;

    .btn-gray {
        margin-right: 10px;
    }

    .btn-yellow {
        margin-left: 10px;
    }
}

.p-invalid {
    position: absolute;
    bottom: -18px;
    font-size: 12px;
    margin-top: 0 !important;
    font-family: 'PublicSans-Regular';
    color: $error;
}

.alartbox {
    position: fixed;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 300px;
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s;
    -webkit-animation: fadein 0.5s;
    -o-animation: fadein 0.5s;
    z-index: 9999;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {

    /* Firefox */
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {

    /* Safari and Chrome */
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {

    /* Opera */
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.initialLink {
    background: transparent !important;
    padding: 5px 0 !important;
    border-bottom: 1px solid $yellow !important;
    border-radius: 0 !important;
    color: $yellow !important;

    &:hover {
        background: transparent !important;
        border-bottom: 1px solid $yellow !important;
        color: $yellow !important;
    }
}

.tdAction {
    .TdICon {
        margin-left: 15px !important;
    }

    .p-button.p-button-icon-only {
        padding: 0 !important;
    }
}

.roleSpan {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 6px;

    &.roleOrange {
        background-color: #fff2d6 !important;
        color: #ffab00 !important;
    }

    &.roleBlue {
        background-color: #e7e7ff !important;
        color: $yellow !important;
    }

    &.rolskyblue {
        background-color: #d7f5fc !important;
        color: #03c3ec !important;
    }

    &.rolskygreen {
        background-color: #e8fadf !important;
        color: #71dd37 !important;
    }

    &.rolskygreen {
        background-color: #ebeef0 !important;
        color: #8592a3 !important;
    }
}

.detailTxtRow {
    display: flex;
    margin-bottom: 15px;

    .detailTxtColLeft {
        min-width: 40%;
        max-width: 40%;
        position: relative;
        padding-right: 15px;
        font-family: 'PublicSans-Bold';
        font-size: 15px;
        color: $black566 !important;

        span {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .detailTxtColRight {
        min-width: 60%;
        max-width: 60%;
        padding-left: 15px;
        font-weight: 500;
        font-size: 14px;
        font-family: 'PublicSans-Medium';
        color: $black566 !important;
    }
}

//text-overflow: ellipsis;
.detailheader {
    padding: 24px;
    border-bottom: 1px solid $grayd9 !important;
}

.saName {
    font-family: 'PublicSans-Bold' !important;

    span {
        background-color: #ebeef0 !important;
        color: #8592a3 !important;
        font-size: 12px;
        text-transform: uppercase;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 4px;
        font-family: 'PublicSans-Medium' !important;
        font-weight: 500;
    }
}

.detailPage {
    .detailTxtColLeft {
        p {
            position: relative;
            display: inline-block;
            padding-right: 10px;
        }
    }
}

.btn.btn-blue {
    background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%);
    border: 0 !important;
    color: $white !important;

    &:hover {
        background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%) !important;
    }

    &:focus {
        background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%) !important;
    }
}

.tableFilterCol {
    .btn.btn-blue {
        background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%);
        color: $white !important;
        padding: 8px 17px !important;
        height: 38px;

        &:hover {
            background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%) !important;
        }
    }
}

.contentBox-w.detailPage {
    min-height: 400px
}

.adminContent {
    .p-paginator .p-paginator-pages .p-paginator-page {
        background-color: $grayf5;
        color: #697a8d;
        min-width: 32px;
        height: 32px;
        margin: 0.143rem;
        border-radius: 4px;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%);
        color: $white !important;
        box-shadow: 0 0.125rem 0.25rem rgb(239 125 78);
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: $grayf5;
        border: 0 none;
        color: #6c757d;
        min-width: 32px;
        height: 32px;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 4px !important;
    }

    .p-paginator {
        justify-content: end;
        margin-top: 0 !important;
    }

    .p-paginator.p-component {
        border-top: 1px solid $grayd9 !important;
        border-radius: 0;
        padding: 24px;
    }
}

.roleboxesrow {
    padding-top: 30px;

    .roleboxesCol {
        background-color: $white;
        box-shadow: 0 0.125rem 0.25rem rgb(193 193 193);
        padding: 24px;
        border-radius: 8px;
        max-width: 100%;
        min-width: 100%;
        position: relative;
        min-height: 135px;

        img.addrole {
            position: absolute;
            width: 100px;
            left: 0;
            bottom: 0;
        }

        .h6 {
            font-size: 15px;
            font-family: 'PublicSans-Medium';
            color: $black566 !important;
            font-weight: 500;
            margin-bottom: 8px;
        }

        .h4 {
            font-size: 22px;
            font-family: 'PublicSans-Medium';
            color: $black566 !important;
            font-weight: 500;

        }

        p {
            cursor: pointer;
            font-family: 'PublicSans-Medium';

            a {
                font-size: 12px;
                color: $yellow;
                font-family: 'PublicSans-Medium';
                font-weight: 500;
            }

            .pi {
                color: #8e9baa !important;
                margin-left: auto !important;
            }
        }
    }
}

.roledialog {
    .h4 {
        font-size: 26px;
        color: $black566;
        font-family: 'PublicSans-Medium';
        font-size: 500;
    }

    .p3 {
        font-size: 14px;
        font-family: 'PublicSans-Medium';
        font-weight: 500;
        color: $black566;
    }

    .p-dialog-header {
        padding: 0 !important;
    }

    .tableContent {
        overflow-y: unset;
        max-height: 100%;
    }

    th {
        border-top: 1px solid $grayd9;
    }
}

.AcIcon {
    width: 20px;
    cursor: pointer;
}

.dialogWidth800 {
    max-width: 800px !important;
    min-width: 800px !important;
}

.addrolebox {
    button.p-button.p-component.btn.btn-blue {
        padding: 8px 12px;
        font-size: 14px;
    }
}

.mselect {}

.p-multiselect-header {
    display: none !important;
}

.p-multiselect-panel .p-multiselect-items {
    padding: 0 0 !important;
}

.p-multiselect.p-component {
    width: 100% !important;
}

.p-multiselect.p-component.p-inputwrapper.w-full.md\:w-20rem {
    border: 1px solid $gray;
}

.addrpleTable {
    th {
        border-top: 1px solid $grayd9;
        border-spacing: 2px
    }

    tr {
        th {
            &:first-child {
                padding-left: 0 !important;
            }

            &:last-child {
                padding-right: 0 !important;
            }
        }
    }

    tr {
        td {
            &:first-child {
                padding-left: 0 !important;
            }

            &:last-child {
                padding-right: 0 !important;
            }
        }
    }
}

.detailDialog {
    .p-dialog-header {
        padding-bottom: 15px !important;
        border-bottom: 1px solid $grayd2;
        padding-top: 15px !important;
    }

    .p-dialog-title {
        padding: 0 20px;
    }
}

.detailDialog .p-dialog-title {
    font-family: 'PublicSans-SemiBold';
}

.addedRoleTable {
    .pi-check:before {
        content: "\e909";
        display: block;
    }
}

.formHeader {
    padding: 20px;
    border-bottom: 1px solid $grayd9;

    .h4 {
        margin-bottom: 0 !important;
    }
}

.tableFilterCol.sortList {
    max-width: 100px;
}

.p-dropdown {
    width: 100% !important;
}

.sepLable {
    display: block;
    font-family: "PublicSans-Regular";
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.pfloatlabel {
    .p-inputtext.p-component {
        padding: 9px 16px;
        height: 38px;
        font-size: 14px;
        font-family: 'PublicSans-Regular' !important;
    }

    .p-dropdown-label.p-inputtext {
        padding: 10px 16px !important;
        height: 36px;
        font-size: 14px;
        font-family: 'PublicSans-Regular' !important;
    }

    .p-multiselect-label {
        padding: 10px 16px !important;
        height: 36px;
        font-size: 14px;
        font-family: 'PublicSans-Regular' !important;
    }

    input::placeholder {
        color: #a8aaad;
        font-size: 14px;
        font-family: 'PublicSans-Regular' !important;
    }

    .p-calendar-w-btn {
        .p-button {
            height: 38px !important;
        }
    }
}

.colwidth250 {
    min-width: 250px;
    max-width: 250px;
}

.p-inputgroup.searchFilter {
    width: 260px;
}

.onlyReadForm {
    .p-inputtext {
        pointer-events: none;
    }

    textarea.p-inputtextarea.p-inputtext.p-component.p-filled {
        height: auto;
    }

    .textarea {
        pointer-events: none;
    }
}

.p-dropdown {
    border: 1px solid $gray;
}

.p-sidebar-content,
.p-dropdown-items-wrapper {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: $grayd2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $grayd2;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}

.btn.btn-white {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(133, 146, 163, .4) !important;
    background: transparent !important;

    span.p-button-label {
        font-family: 'PublicSans-Regular' !important;
        font-weight: 400 !important;
    }
}

.category-section {
    padding: 24px;

    .p-accordion-header-link {
        position: relative;
        // padding: 5px 20px !important;
        padding: 0% !important;

        .p-accordion-toggle-icon {
            position: absolute;
            right: 10px;
        }

        .productImageTd {
            width: 40px;
            height: 40px;
            object-fit: contain;
            padding: 5px;
            margin-right: 15px;
        }
    }

    .p-accordion .p-accordion-content {
        padding: 16px 0px;
    }
}


.category-section {
    .p-accordion .p-accordion-tab {
        margin-bottom: 10px;
    }

    .mainCatHeader {
        .p-accordion-header-link {
            border: 1px solid $grayd9;
            color: $black566 !important;
            background: $grayfb !important;
            font-size: 20px;
            font-family: 'PublicSans-Medium';
            font-weight: 500;

            &:hover {
                background: #fbfbfb !important;
            }

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }
        }

        .catimagePreview {
            margin-right: 10px;
        }
    }

    .subCategoryBox {
        .p-accordion-content {
            border-left: 0 !important;
            border-right: 0 !important;
        }

        .p-accordion-tab {
            border: 1px solid #ccc !important;
            border-radius: 5px !important;
            border-bottom: 0 !important;
        }
    }
}

.specificationsBox {
    padding: 0 20px;

    h4 {
        font-size: 16px;
        color: $black566 !important;
        font-family: 'PublicSans-Medium';
        font-weight: 500;
    }

    .specList {
        display: inline-block;
        min-width: 300px;
        max-width: 100%;

        .p1 {
            padding-left: 30px;
            position: relative;
            font-size: 14px;

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background: $black566;
                display: block;
                position: absolute;
                left: 12px;
                top: 9px;
                border-radius: 50px;
            }
        }

        .specActionBtns {
            margin-left: auto !important;

            .AcIcon {
                margin: 0 6px !important;
            }
        }
    }

    .btn.btn-blue {
        padding: 10px 15px;
        height: 38px;

        .pi-plus {
            margin-right: 6px;
        }
    }
}

.subCategoryBox {
    padding: 0 20px;

    span.p-accordion-header-text {
        width: 100%;
    }

    .subCatHeaderCl {
        max-width: 25%;
        min-width: 25%;

        &.viewSpecCol {
            max-width: 50%;
            min-width: 50%;
            text-align: right;

            button.p-button.p-component.btn.btn-blue.viewspecification {
                padding: 7px 10px;
                font-size: 14px;

                .pi {
                    font-size: 12px;
                    margin-left: 6px;
                }
            }
        }

        .catimagePreview.featureIcon {
            width: 40px;
            height: 40px;

            .productImageTd {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 5px;
                margin-right: 15px;
            }
        }

        &:nth-child(1) {
            max-width: fit-content;
            min-width: auto;
        }

        .vertical-align-middle {
            color: $black566 !important;
            font-size: 17px;
        }
    }
}

.subCategoryBox {
    .p-accordion-header {
        .p-accordion-header-link {
            background: transparent !important;
            border: 0 !important;
            // padding-bottom: 10px !important;
            // padding-top: 10px !important;
            border-bottom: 1px solid #ccc !important;
            font-family: 'PublicSans-SemiBold' !important;
            font-weight: 500 !important;

            &:hover {
                background: transparent !important;
            }

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }

            span.p-accordion-toggle-icon.pi.pi-chevron-right {
                display: none !important;
            }

            span.p-accordion-toggle-icon.pi.pi-chevron-down {
                display: none !important;
            }
        }

        .subCatHeader.flex.align-items-center {
            padding: 5px 20px !important;
        }
    }
}

.wholesalesidebar {
    width: 35rem !important;

    th,
    td {
        text-align: center !important;
    }

    .tableContent tbody td:first-child {
        padding-left: 0;
    }

    .tableContent thead th:first-child {
        padding-left: 0;
    }
}

.textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 40px;
    line-height: 20px;
    border: 1px solid $gray;
    font-family: inherit;
    font-size: inherit;
    padding: 10px 16px;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    border-radius: 6px;
    font-size: 14px;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.combDateBox {
    display: inline-flex;
    align-items: center;
    position: relative;
    border: 1px solid $grayd2;
    border-radius: 5px;

    .p-datepicker-trigger {
        // display: none !important;
        left: 0 !important;
        width: 100%;
        opacity: 0;
        background: transparent;
        position: absolute;
    }

    .p-inputwrapper {
        input.p-inputtext.p-component.p-filled {
            border: 0 !important;
            width: 90px;
        }
    }

    .p-calendar .p-inputtext {
        border: 0 !important;
        padding: 0 !important;
        font-size: 14px;
        text-align: center;
    }

    span.p-calendar {
        width: 90px;
    }

    .c-label {
        background: #f5f5f5;
        display: block;
        padding: 0px 10px;
        font-size: 14px;
        height: 38px;
        line-height: 38px;
        border-radius: 4px 0 0 4px;
        font-family: 'PublicSans-Regular';
    }
}

p.pnor15 {
    color: #697a8d;
    font-family: 'PublicSans-Regular';
    font-size: 15px;
    line-height: 24px;
}

.dtlRow {
    .dtlLabelCol {
        .dtlLabel {
            color: #697a8d;
            font-family: 'PublicSans-Regular';
            font-size: 15px;
            line-height: normal;
            position: relative;
            display: inline-block;

            .dividerCol {
                position: absolute;
            }
        }
    }

    .dtlValueCol {
        color: #697a8d;
        font-family: 'PublicSans-SemiBold';
        font-size: 15px;
        line-height: normal;
        padding-left: 10px;
    }
}

.divider {
    background: $grayd9;
    height: 1px;
    width: 100%;
}

.orderdetailrow {
    .h6 {
        font-size: 15px;
        color: $black566;
        font-family: 'PublicSans-Medium';
        font-weight: 500;
    }
}

.orderdeliverdtl {
    .dtlRow {
        margin-bottom: 8px;
    }

    .dtlLabel {
        color: #697a8d;
        font-family: 'PublicSans-Regular';
        font-size: 15px;
        line-height: normal;
        position: relative;
        display: inline-block;

    }

    .dtlLabelCol {
        min-width: 150px;
    }
}

.onorderlogo {
    width: 100px;
    max-width: 100px;
}

.orderdetailrow {
    th {
        border-top: 1px solid $grayd9;
    }
}

.orderPaymentDetail {
    .dtlRow {
        margin-bottom: 8px;
    }

    .dtlLabelCol {
        min-width: 150px;
        text-align: right;

        .dtlLabel {
            padding-right: 10px;

            .dividerCol {
                right: 0;
            }
        }
    }

    .dtlValueCol {
        min-width: 125px;
        text-align: right;
    }
}

.orderActionbtns {
    .btn {
        width: 100%;
        display: block;
    }
}

.p-inputnumber {
    span.p-inputnumber-button-group .p-inputnumber-button:first-child {
        border-bottom: 0 !important;
    }

    span.p-inputnumber-button-group .p-inputnumber-button:last-child {
        border-top: 0 !important;
    }
}

.blue-outline {
    border: 1px solid $yellow !important;
    color: $yellow !important;
    background: transparent !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $yellow;
    background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%);

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $yellow;
    background: linear-gradient(113.49deg, #E8336E 10.85%, $yellow 77.92%);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $yellow;
}

button.p-button.p-component.btn.btn-blue.viewspecification {
    background: transparent !important;
    border: 1px solid $yellow !important;
    color: $yellow !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none !important;
    outline: none !important;
}

.capstioninSd {
    .p-dropdown .p-dropdown-trigger {
        width: 1rem !important;
        padding: 0 14px !important;
    }

    span.p-dropdown-label.p-inputtext.p-placeholder {
        padding-right: 0 !important;
        padding: 10px 10px !important;
    }

    input.p-inputtext.p-component {
        min-width: 75% !important;
        max-width: 75% !important;
        border-radius: 0 5px 5px 0 !important;
        border-left: 0 !important;

    }

    .currencycapstiondd {
        max-width: 25% !important;
        min-width: 25% !important;
        height: 38px;
    }

    .pfloatlabel .p-dropdown-label.p-inputtext {
        padding: 10px 10px !important;
    }

    span.p-dropdown-label.p-inputtext {
        padding: 10px 10px !important;
    }
}

.UploadDialog {
    .p-dialog-header {
        padding-bottom: 30px;
        padding-top: 20px;
        text-align: center;

        button.p-dialog-header-icon.p-dialog-header-close.p-link {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
}

.uploadimagecntD {
    text-align: center;

    .uploadimagebtn {
        position: relative !important;
        display: inline-block !important;
        width: auto !important;

        input[type="file"] {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            font-size: 0;
            opacity: 0;
            left: 0;
            top: 0;
        }
    }

    .p2 {
        line-height: 24px;
    }
}

.p-dropdown.error-border {
    border: 1px solid #f55e71 !important;
}

.formField {
    position: relative;
}

.new-error {
    .p-component {
        border-color: #ff6b68;
    }
}

.p-sidebar.p-component.detailSiderbar.p-ripple-disabled.p-sidebar-enter-done::after {
    content: '';
    position: fixed;
    left: 0px;
    right: auto;
    width: calc(100% - 400px);
    height: 100%;
    background: transparent;
}

.afterClickClose::after {
    display: none;
}

.roleboxesrow {
    .roleboxesCol {
        min-height: 160px;

        .h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            position: relative;
            -webkit-line-clamp: 2;
            word-wrap: break-word;
        }
    }
}


// PhoneInput
.PhoneInput {
    border: 1px solid #959595;
    border-radius: 4px;
    outline: none;
    box-shadow: none !important;
    height: 38px;
    width: 100%;
    padding: 12px 16px;
    direction: ltr;
    font-family: 'PublicSans-Medium';

    &:hover {
        border-color: $yellow !important;
    }

    .PhoneInputCountry {
        margin-right: 8px;
        font-family: 'PublicSans-Medium';
    }

    .PhoneInputInput {
        border: none;
        outline: none;
        box-shadow: none !important;
        font-family: "PublicSans-Medium";
        font-size: 16px;
        color: #787777;
        height: 100%;
    }
}

.passwordIcon span {
    top: 0 !important;
}

i.passwordIcon {
    top: 14px;
}

.p-accordion-header.w-full-header .p-accordion-header-text {
    width: 100%;
    // border: 1px solid red;
    margin: -5px -20px;
    width: calc(100% + 40px);
    padding: 5px 20px;
}

.p-accordion-header.w-full-header .p-accordion-header-text div.flex.align-items-center {
    padding: 5px 20px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.passwordIcon {
    top: 11px;
}

.passwordIcon span {
    top: 0 !important;
}

i.passwordIcon {
    top: 14px;
}

.wsaleRow {
    .p-error {
        font-size: 12px;
        margin-top: 0 !important;
        font-family: "PublicSans-Regular";
        color: #E93D3D;
    }

    .currencycapstiondd {
        max-width: 30% !important;
        min-width: 30% !important;
    }

    .formField .p-float-label>label {
        font-size: 13px;
    }

    .p-dialog.p-component.addWholsaleDialog .SellingPriceCol {
        max-width: 18%;
        min-width: 18%;
    }

    .p-dialog.p-component.addWholsaleDialog .MaxQuantityCol {
        min-width: 14%;
        max-width: 14%;
    }
}

.p-dialog.p-component.addWholsaleDialog .SellingPriceCol .fieldLeftCaption span.p-float-label {
    min-width: 70% !important;
    max-width: 70% !important;
}

.p-dialog.p-component.addWholsaleDialog .wsaleCol .formField .p-float-label>label {
    font-size: 13px;
}

.p-dialog.p-component.addWholsaleDialog .SellingPriceCol {
    max-width: 18%;
    min-width: 18%;
}

.p-dialog.p-component.addWholsaleDialog .MinQuantityCol {
    min-width: 16%;
    max-width: 16%;
}

.addWholsaleDialog .formField .p-inputtext {
    font-size: 14px;
}

.fieldLeftCaption .currencycapstiondd {
    height: 42px;
}

.addWholsaleDialog .formField .p-dropdown span.p-dropdown-label.p-inputtext {
    font-size: 14px;
}

.addWholsaleDialog .formField .p-float-label .p-dropdown {
    min-height: 42px !important;
    max-height: 42px !important;
}

.errer-border {
    border: 1px solid red;
    border-radius: 5px;
}

.hover-border-none {
    .p-checkbox-box {
        &:hover {
            border-color: #ced4da !important;
        }
    }
}

.ConversionName {
    position: absolute;
    right: 1px;
    background: #ededed;
    height: 43px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    top: 1px;
    border-radius: 0 6px 6px 0;
    font-family: 'PublicSans-Medium';
}

.to {
    font-family: 'PublicSans-Medium';
    font-size: 15px;
}

.yellow {
    color: #F9B000 !important;
}

.productimage {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid #ccc;
}

.stepsview {
    .p-steps .p-steps-item:first-child::before {
        width: 50%;
        left: 120px;
    }

    span.p-steps-number {
        font-size: 0 !important;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        min-width: 12px;
        height: 12px;
        line-height: 12px;
    }

    .p-steps .p-steps-item:last-child::before {
        width: 50%;
        right: 90px;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: #64CD82;
    }

    .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
        outline: none;
        box-shadow: none;
    }

}

.trackingsteps {
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    .stepLi {
        min-width: 15%;
        max-width: 20%;
        list-style: none;
        position: relative;

        &::after {
            content: "";
            background: #EDF4F4;
            width: 96%;
            height: 1px;
            position: absolute;
            right: auto;
            top: 5px;
            left: -95%;
        }

        .stepCircle {
            background: #fff;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            display: block;
            margin-bottom: 12px;
            border: 1px solid #EDF4F4;
        }

        &:first-child {
            &::after {
                display: none;
            }
        }

        &.activeStep {
            &::after {
                content: '';
                background: #64CD82;
            }

            .stepCircle {
                background: #64CD82;
            }
        }
    }
}

.p-dropdown-item {
    white-space: normal;
    word-break: break-all;
    line-break: anywhere;
}

ul.p-dropdown-items {
    max-width: unset;
}

.sidebarContentBody .pfloatlabel .p-inputtext.p-component {
    height: 42px;
}

.sidebarContentBody .pfloatlabel .p-calendar-w-btn .p-button {
    height: 42px !important;
}

.tableContent.userlisttable tbody td {
    padding: 0 16px;
    // border-top: 0 !important;
    // border-bottom: 0 !important;
}

//   .tableContent.userlisttable tbody tr:first-child td{
//     border-top:0 !important;
//   }
//   .tableContent thead th{
//   border: 0 !important;
//   }
.subCategoryBox svg.p-icon.p-accordion-toggle-icon {
    display: none;
}

.capitalize-first-letter {
    text-transform: capitalize;
}

.p-dialog.p-component.UploadDialog.UploadDialogWidth {
    max-width: 880px !important;
    min-width: 880px !important;
}

.product-editor .ql-toolbar button.ql-image {
    display: none !important;
}

.messageTd {
    white-space: unset !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
    min-width: 350px;
}

.product-40 {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: contain;
    object-position: center;
}

.bg-gray {
    background: #fbfbfb;

    th {
        background: #ffffff !important;
    }
}

.animated-height {
    opacity: 0;
    height: fit-content;
    transition: opacity .5s ease-in-out;

    &.auto {
        opacity: 1;
    }
}

.custom-tooltip {
    &.p-tooltip {
        .p-tooltip-arrow {
            border-right-color: #E93D3D !important;
        }

        .p-tooltip-text {
            background: #E93D3D;
            color: #ffffff;
            font-size: 12px;
            padding: 0.75rem 0.75rem;
            box-shadow: none;
            border-radius: 8px;
            max-width: 290px;
        }
    }
}

.p-card.new-card {
    border-radius: 6px !important;
    border: 1px solid #EDF4F4;
    box-shadow: none !important;

    .p-card-body {
        padding: 0px !important;

        .p-card-title {
            background: #F7F7F7;
            padding: 10px 16px;
            margin-bottom: 0px;
            font-size: 14px !important;
            color: #12262F !important;
        }

        .p-card-content {
            padding: 16px 16px !important;
            min-height: 95px;
        }
    }
}

.tracking-box {
    padding: 20px;
    background: #FAFCFC;
    border: 1px solid #EDF4F4;
    border-radius: 4px;
    position: relative;
    z-index: 0;
    margin-top: 16px;
}

.border-box {
    padding: 20px;
    border: 1px solid #EDF4F4;
    border-radius: 4px;
    min-width: 50%;
    width: 100%;
}

.border-to-the-box {
    padding: 20px;
    border: 1px solid #EDF4F4;
    border-radius: 4px;
}

.order-detail-accordion {
    .p-accordion-tab {
        margin-top: 30px;
        margin-bottom: 0px;

        .p-accordion-header-link {
            background: #FAFCFC !important;
            border: 1px solid #EDF4F4 !important;
            border-radius: 4px 4px 0px 0px !important;

            .p-accordion-header-text {
                display: block;
                width: 100%;
            }
        }

        .p-accordion-content {
            border: 1px solid #EDF4F4 !important;
            border-radius: 0px 0px 4px 4px !important;
        }
    }
}

.product-chips-status {
    padding: 4px 14px;
    border-radius: 6px;
    display: inline-block;
    font-size: 12px !important;
    text-transform: capitalize;
    font-weight: 500;

    &.confirmed {
        background: #EFF8EB;
        color: #59AA00;
    }

    &.canceled {
        background: #f7ccc8;
        color: #cb1f35;
    }

    &.cancelled {
        background: #f7ccc8;
        color: #cb1f35;
    }

    &.shipped {
        background: #FFF6ED;
        color: #EE9C16;
    }

    &.pickup {
        background: #EFF8EB;
        color: #59AA00;
    }

    &.delivered {
        background: #EFF8EB;
        color: #59AA00;
    }

    &.returned {
        background: #fddeda;
        color: #ad2a3a;
    }
}